<template>
  <section>
    <h2 class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Markisen / Vollkassettenmarkise / Toscana <br />
    </h2>
    <article class="container mx-auto pl-2 pt-2 lg:p-32">
      <h2 class="text-4xl md:text-6xl pb-10" style="color: #f27405">
        Toscana - spielerische Leichtigkeit
      </h2>
      <div class="grid grid-cols-2">
        <div class="col-span-full lg:col-span-1">
          <productslide v-bind:slides="slides"></productslide>
        </div>

        <div class="col-span-full lg:col-span-1">
          <h3
            class="text-2xl pl-5 py-5 md:pl-10 md:pb-3"
            style="color: #033859"
          >
            ein Klassiker für Balkone und mittelgroße Terrassen
          </h3>
          <p class="px-5 w-full md:pl-10 md:pb-3">
            Als preisgekrönter Designklassiker aus dem Hause Lewens präsentiert
            sich die Markise Toscana in zeitlos eleganter Form und setzt
            modische Akzente bei der Gestaltung von Balkon und Terrasse.
            Besonderes Highlight: Die Toscana lässt sich mit einem besonders
            steilen Neigungswinkel montieren und schafft damit einen
            zusätzlichen Blend- und Sichtschutz.<br /><br />
            <span class="font-semibold"
              >Die 13 cm hohe Kassette schützt das Tuch im eingefahrenen Zustand
              zuverlässig vor Witterungseinflüssen.</span
            >
          </p>
        </div>
      </div>
      <div class="p-4">
        <h3 class="text-4xl pt-10 pb-3" style="color: #033859">Eckdaten</h3>
        <tabs :mode="mode">
          <tab title="Maßen">
            <p class="text-lg">
              <span class="font-bold">Breite:</span> <br />
              max. 550 cm - 1-teilig (Einzelfeld)<br />
              <span class="font-bold">Ausfall/Tiefe:</span> <br />
              max. 300 cm <br />
              <span class="font-bold">Antrieb:</span> <br />
              Motorantrieb <br />
              Kurbelantrieb (Option)<br />
              <span class="font-bold">Volant:</span> <br />
              Volant möglich (Option) <br />
              <span class="font-bold">Markisentücher:</span> <br />
              Marken-Acryl <br />
              Marken-Polyester (Option)
            </p>
          </tab>
          <tab title="Extras">
            <p class="text-lg">
              Toscana -Vollkassettenmarkise ist in verschiedenen Farben und
              Ausführungen erhältich. <br />
              Besuchen Sie unsere Ausstellung oder lassen Sie sich beraten.
            </p>
          </tab>
          <tab title="Zertifizierung">
            <div class="text-lg">
              <span class="font-bold">Zertifizierung:</span> <br />
              <div class="flex flex-col">
                <div class="flex flex-row">
                  <div>
                    <img
                      src="../assets/madeingermany.webp"
                      alt="made in germany"
                    />
                  </div>
                  <div class="w-full pl-1">
                    Hergestellt und montiert in Deutschland
                  </div>
                </div>
                <div class="flex flex-row">
                  <div><img src="../assets/ce-norm.webp" alt="ce-norm" /></div>
                  <div class="w-full pl-1">
                    Qualität und Technik nach CE-Norm
                  </div>
                </div>
                <div class="flex flex-row">
                  <div>
                    <img src="../assets/tuev.webp" alt="tuev geprueft" />
                    TÜV-geprüfte Sicherheit
                  </div>
                  <div class="w-full pl-1"></div>
                </div>
              </div>
            </div>
          </tab>
        </tabs>
      </div>
    </article>
  </section>
</template>
<script>
import Tab from "../components/Tab";
import Tabs from "../components/Tabs";
import productslide from "../components/product-slide";

export default {
  components: {
    Tab,
    Tabs,
    productslide,
  },
  data() {
    return {
      slides: [
        {
          image: require("@/assets/img/lewens/toscana/lew-toscana-ganz-haus-uli.webp"),
          alt: "vollkassettenmarkise toscana",
        },
        {
          image: require("@/assets/img/lewens/toscana/modelle-web-rd-8.webp"),
          alt: "vollkassettenmarkise toscana modell",
        },
      ],
    };
  },
};
</script>
